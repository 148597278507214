<template>
  <div>
    <el-row class="vd_row">
      <el-col :md="3">
        <span>选择机器编号:</span>
      </el-col>
      <el-col :md="8">
        <el-select @change="selectApplet" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.param1"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="vd_row vg_mt_16">
      <div v-if="value"  id="printTest" style="display: flex;flex-direction: column;align-items: center;width:160px;height:287px;font-size:14px;padding-left:10px;padding-top:5px;">
        <div style="fontSize:24px;">机器编号</div>
        <div style="fontSize:24px;">{{value1}}</div>
        <img style="width:150px;height:150px;" :src = "machineImg">
      </div>
    </el-row>
    <el-row class="vd_row vg_mt_32 vg_mb_16">
      <el-button type="primary" size="small" @click="print">打印</el-button>
    </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import { optnAPI } from "@api/modules/optn";
import axios from "axios";
import {getLodop} from '@/plugins/LodopFuncs'
export default {
  name:'AppletList',
  data(){
    return{
      options: [],
        value: '',
        value1: '',
        machineImg:''
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      get(optnAPI.getOptnByPermId,{perm_id : 10002})
      .then(res=>{
        if(res.data.code === 0) {
          this.options = res.data.data.form.optn_cntt_list
        }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 更换机器
    selectApplet(val){
      let str = '';
      for(let i=0;i<this.options.length;i++){
        if(this.options[i].id === this.value){
          str = this.options[i].param1;
          this.value1 = this.options[i].param1;
        }
      }
      let url = this.helper.machineInspection(str,this.value)
      axios({
        url: url,
        method: 'get',
      }).then(res => {
        let imgurl = res.data.data.image;
        this.machineImg = 'data:image/jpeg;base64,'+imgurl;
      })
    },
    print(){
      const LODOP = getLodop()
      const strap = '<body>' + document.getElementById('printTest').innerHTML + '</body>'
      // LODOP.PRINT_INIT('')
      // LODOP.SET_PRINT_PAGESIZE(1,'50mm',80*this.multiSelection.length+'mm',"")
      // LODOP.SET_PRINT_PAGESIZE(1,'50mm','80mm','')
      // LODOP.SET_PRINT_STYLE('Alignment','2')
      LODOP.ADD_PRINT_HTM(10,5,400,400,strap)
      // for(let i=0;i<this.multiSelection.length;i++){
      //   let temp = this.multiSelection[i].qrcd_url
      //   LODOP.SET_PRINT_STYLE("FontSize",12)
      //   LODOP.ADD_PRINT_HTM(10,5,400,400,strap)
      //   // LODOP.ADD_PRINT_IMAGE(80*(i)+0+'mm','5mm','40mm','40mm',"<img width='140px'height='140px' src='http://192.168.1.38:8885/file"+temp+".jpg'/>")
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+40+'mm','0mm','50mm','10mm',"物料编号："+this.multiSelection[i].mtrl_no)
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+48+'mm','0mm','50mm','10mm',"物料名称："+this.multiSelection[i].mtrl_name)
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+56+'mm','0mm','50mm','10mm',"库位名称："+this.multiSelection[i].qrcd_bin_no)
      // }
      // LODOP.SET_PRINT_COPIES(this.multiSelection.length)
      LODOP.PREVIEW()
      // setTimeout(() => {
      //   this.cancel()
      // }, 2000);
    },
  },
}
</script>

<style scoped lang="scss">
.vd_row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vd_img{
  width: 200px;
  height: 200px;
}
.vd_img_icon{
font-size: 50px;
}
::v-deep .image-slot{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vd_div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>