/**
 * @description 时间戳转换时间 - 无时分秒
 * @param {number} any 无时分秒的时间戳
 * @param {boolean} ten 是否是10位数
 * @return {string|null} 字符串日期(无时分秒)
 * @author HuangJun
 */
export const getDateNoTime = (any, ten) => {
  if (!any || any === 0) return '暂无';
  any = Number(any);
  if (ten) any *= 1000;
  let now;
  if (any instanceof Date) now = any;
  else now = new Date(any);
  let y = now.getFullYear();
  let m = now.getMonth() + 1;
  let d = now.getDate();
  return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`;
};
/**
 * @description 时间戳转换时间
 * @param {number} any 时间戳
 * @param {boolean} ten 是否是10位数
 * @return {string|null} 字符串日期
 * @author HuangJun
 */
export const getDate = (any, ten) => {
  if (!any || any === 0) return '暂无';
  any = Number(any);
  if (ten) any *= 1000;
  let now;
  if (any instanceof Date) now = any;
  else now = new Date(any);
  let y = now.getFullYear();
  let m = now.getMonth() + 1;
  let d = now.getDate();
  return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${now.toTimeString().substring(0, 8)}`;
};
/**
 * @description 特殊时间处理 2023-03-28T16:00:00.000+00:00
 * @param string 时间字符串
 * @return {string} 处理后时间字符串
 */
export const handleSpecialTime = string => {
  return string.substring(0, string.lastIndexOf('.')).replace('T', ' ');
};
