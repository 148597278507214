<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row class="vg_mb_8">
        <el-col :md="12"> </el-col>
        <el-col :md="12" class="vd_flex_right">
          <el-link class="vg_cursor" type="primary" @click="openApplet">机检小程序码</el-link>
        </el-col>
      </el-row>
      <div class="vd_bor vg_mb_8"></div>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMacpsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="机器编号:">
                <el-select size="small" v-model="searchForm.mach_id" placeholder="请选择机器编号" clearable>
                  <el-option v-for="item in machList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="操作员:">
                <el-input size="small" v-model.trim="searchForm.stff_name" placeholder="请输入姓名" clearable> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="userInfo.acct_id == 1">
              <el-form-item label="所属账套:" label-width="82px">
                <el-select v-model="searchForm.acct_id" filterable placeholder="请选择所属账套" clearable size="small">
                  <el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12">
              <el-form-item label="机检日期:">
                <!-- <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker> -->
                <el-date-picker size="small" v-model="searchForm.timeValue.startTime" align="right" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMacpsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="8" style="display: flex" v-if="userInfo.acct_id === 1">
              <el-input v-model="month" size="small" />
              <el-button type="danger" size="small" @click="getNoCatch('/api/imge/upload_wx_images?month=' + month)">CUT</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <!-- <el-form-item label="结束时间：">
                  <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue1"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item> -->
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" v-loading="loadingFlag" :data="tableData" border>
            <el-table-column label="所属账套" prop="acct_no" />
            <el-table-column label="机器编号" prop="mach_no" />
            <el-table-column label="机器用途" prop="useTo" />
            <el-table-column label="操作员" prop="stff_name">
              <template v-slot="scope">
                <div v-if="scope.row.stff_name">
                  {{ scope.row.stff_name }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="机器状态" prop="macp_status" show-overflow-tooltip width="130">
              <template v-slot="scope">
                <div>
                  <span v-if="scope.row.macp_status === 2" class="vd_disable">{{ scope.row.macp_status | formatMacpStatus }}</span>
                  <span v-else-if="scope.row.macp_status === 1" class="vd_disable1">{{ scope.row.macp_status | formatMacpStatus }}</span>
                  <span v-else-if="scope.row.macp_status === 3" style="color: red">{{ scope.row.macp_status | formatMacpStatus }}</span>
                  <span v-else class="vd_disable2">{{ scope.row.macp_status | formatMacpStatus }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="机检日期" :formatter="formatDate" show-overflow-tooltip width="130" />
            <el-table-column label="机检时间" show-overflow-tooltip :formatter="formatTime" width="150" />
            <el-table-column label="机检用时" prop="use_time_str" show-overflow-tooltip width="150" />
            <el-table-column label="机检间隔" prop="last_time_str" show-overflow-tooltip width="150" />
            <el-table-column label="检测块" align="center">
              <template v-slot="scope">
                <div v-if="scope.row.dete_imge_url" class="vd_div_c">
                  <el-image lazy style="height: 80px" :preview-src-list="[scope.row.dete_imge_url]" :src="scope.row.dete_imge_url_s" />
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="灵敏刻度" align="center">
              <template v-slot="scope">
                <div v-if="scope.row.send_imge_url" class="vd_div_c">
                  <el-image lazy style="height: 80px" :preview-src-list="[scope.row.send_imge_url]" :src="scope.row.send_imge_url_s" />
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column :label="'上' + item" align="center" v-for="(item, index) in 3" :key="'macp_top' + index">
              <template v-slot="scope">
                <div v-if="scope.row.imge_list[index]" class="vd_div_c">
                  <el-image
                    lazy
                    style="height: 80px"
                    :preview-src-list="[scope.row.imge_list[index].imge_url]"
                    :src="scope.row.imge_list[index].imge_url_s"
                  >
                    <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
                  </el-image>
                  <div class="vd_div_new" style="pointer-events: none">
                    <span :class="`iconfont ${scope.row[`macp_top${index + 1}`] === 1 ? 'icon-duihao' : 'icon-cuohaoyuan'}`" />
                  </div>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column :label="'中' + item" align="center" v-for="(item, index) in 3" :key="'macp_mid' + index">
              <template v-slot="scope">
                <div v-if="scope.row.imge_list[index]" class="vd_div_c">
                  <el-image
                    lazy
                    style="height: 80px"
                    :preview-src-list="[scope.row.imge_list[index + 3].imge_url]"
                    :src="scope.row.imge_list[index + 3].imge_url_s"
                  >
                    <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
                  </el-image>
                  <div class="vd_div_new" style="pointer-events: none">
                    <span :class="`iconfont ${scope.row[`macp_mid${index + 1}`] === 1 ? 'icon-duihao' : 'icon-cuohaoyuan'}`" />
                  </div>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column :label="'下' + item" align="center" v-for="(item, index) in 3" :key="'macp_bel' + index">
              <template v-slot="scope">
                <div v-if="scope.row.imge_list[index]" class="vd_div_c">
                  <el-image
                    lazy
                    style="height: 80px"
                    :preview-src-list="[scope.row.imge_list[index + 6].imge_url]"
                    :src="scope.row.imge_list[index + 6].imge_url_s"
                  >
                    <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
                  </el-image>
                  <div class="vd_div_new" style="pointer-events: none">
                    <span :class="`iconfont ${scope.row[`macp_bel${index + 1}`] === 1 ? 'icon-duihao' : 'icon-cuohaoyuan'}`" />
                  </div>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="金属探测记录表" align="center">
              <template v-slot="scope">
                <div v-if="scope.row.record_image_url" class="vd_div_c">
                  <el-image
                    lazy
                    style="height: 80px"
                    :preview-src-list="[scope.row.record_image_url]"
                    :src="scope.row.record_image_url_s"
                  />
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="录入人" align="" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina">
              <template v-slot="scope">
                <div v-if="scope.row.stff_name">
                  <div>{{ helper.personCombina(scope.row) }}</div>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
                <div class="vd_three">
                  <span>
                    <el-link type="primary" class="vg_cursor" @click="openRemark(scope.row)">备注：{{ scope.row.shutdown_remark }}</el-link>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog title="机检小程序码" width="50%" :visible.sync="appletVisible">
        <AppletList></AppletList>
      </el-dialog>
      <el-dialog title="备注" width="50%" :visible.sync="Visible" :before-close="handleClose">
        <el-row class="vg_mb_8">
          <el-col :md="24" class="vd_dis_right">
            <el-button type="primary" size="mini" @click="submitClick()">保存</el-button>
          </el-col>
        </el-row>
        <el-row class="vg_mb_8">
          <el-col :md="2"> 备注: </el-col>
          <el-col :md="22">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" placeholder="请输入内容" v-model="remarkFile"> </el-input>
          </el-col>
        </el-row>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { macpAPI } from '@api/modules/macp';
import { optnAPI } from '@api/modules/optn';
import { acctAPI } from '@api/modules/acct';
import pubPagination from '@/components/common/pubPagination';
import imgColorPop from '@/views/component/imgColorPop';
import helper from '@assets/js/helper.js';
import AppletList from './TabChild/AppletList.vue';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'macpList',
  components: {
    pubPagination,
    imgColorPop,
    AppletList
  },
  data() {
    return {
      month: 2023,
      searchForm: {
        mach_id: null,
        macp_id: null,
        acct_id: null,
        stff_name: null,
        timeValue: {
          startTime: null,
          endTime: null
        },
        timeValue1: {
          startTime: null,
          endTime: null
        }
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      tableData1: [],
      tableDataList: [],
      multiSelection: [],
      btn: {},
      loadingFlag: true,
      machList: [],
      imgUpdatatime: parseInt(Math.random() * 100000000),
      appletVisible: false,
      userInfo: this.$cookies.get('userInfo'),
      acctList: [],
      Visible: false,
      remarkFile: ''
    };
  },
  created() {
    if (this.$route.query.macp_id) {
      this.searchForm.macp_id = this.$route.query.macp_id;
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/macp_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 产品类型转换
    formatMacpType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    },
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    },
    // 机器状态
    formatMacpStatus(row) {
      if (row === 0) {
        return '检测通过';
      } else if (row === 1) {
        return '未检测';
      } else if (row === 2) {
        return '机检异常';
      } else if (row === 3) {
        return '不开机';
      }
    }
  },
  methods: {
    getNoCatch,
    initData() {
      this.loadingFlag = true;
      this.getMacps();
      this.getCompType();
      if (this.userInfo.acct_id == 1) {
        this.getAcct();
      }
    },
    // 获取产品信息
    getMacps() {
      let startTime = Number(new Date(this.searchForm.timeValue.startTime).getTime() / 1000);
      let endTime = null;
      if (startTime) {
        endTime = startTime + 24 * 60 * 60;
      } else {
        startTime = null;
        endTime = null;
      }
      get(macpAPI.getMacps, {
        page_no: this.currentPage,
        mach_id: this.searchForm.mach_id,
        acct_id: this.searchForm.acct_id,
        macp_id: this.searchForm.macp_id,
        stff_name: this.searchForm.stff_name,
        start_time: startTime,
        end_time: endTime
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.searchForm.macp_id = null;
            for (let j = 0; j < this.tableData.length; j++) {
              let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
              if (this.tableData[j].dete_imge_url) {
                this.tableData[j].dete_imge_url_s = this.helper.picUrl(this.tableData[j].dete_imge_url, 's', updateTime, 0);
                this.tableData[j].dete_imge_url = this.helper.picUrl(this.tableData[j].dete_imge_url, 'l', updateTime, 0);
              }
              if (this.tableData[j].record_image_url) {
                this.tableData[j].record_image_url_s = this.helper.picUrl(this.tableData[j].record_image_url, 's', updateTime, 0);
                this.tableData[j].record_image_url = this.helper.picUrl(this.tableData[j].record_image_url, 'l', updateTime, 0);
              }
              if (this.tableData[j].send_imge_url) {
                this.tableData[j].send_imge_url_s = this.helper.picUrl(this.tableData[j].send_imge_url, 's', updateTime, 0);
                this.tableData[j].send_imge_url = this.helper.picUrl(this.tableData[j].send_imge_url, 'l', updateTime, 0);
              }
              for (let i = 0; i < this.tableData[j].imge_list.length; i++) {
                this.tableData[j].imge_list[i].imge_url_s = this.helper.picUrl(this.tableData[j].imge_list[i].imge_url, 's', updateTime, 0);
                this.tableData[j].imge_list[i].imge_url = this.helper.picUrl(this.tableData[j].imge_list[i].imge_url, 'l', updateTime, 0);
              }
            }
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then(res => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取机器编号
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10002 })
        .then(res => {
          if (res.data.code === 0) {
            this.machList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 备注保存
    submitClick() {
      let params = {};
      params.macp_id = this.macpId;
      params.macp_remark = this.remarkFile;
      post(macpAPI.editMacpRemark, params)
        .then(res => {
          if (res.data.code === 0) {
            this.remarkFile = '';
            this.macpId = null;
            this.Visible = false;
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    handleClose(done) {
      this.$confirm('是否保存？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.submitClick();
        })
        .catch(() => {});
    },
    // 打开备注弹框
    openRemark(val) {
      /*      this.Visible = true;
      this.remarkFile = val.macp_remark;
      this.macpId = val.macp_id;*/
    },
    // 打开打印弹框
    openApplet() {
      this.appletVisible = true;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mach_id: null,
        acct_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        },
        timeValue1: {
          startTime: null,
          endTime: null
        }
      };
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMacpsNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 反显机检日期
    formatDate(row) {
      if (row.macp_status === 3) return getDateNoTime(row.start_end_time, false) + '~' + getDateNoTime(row.shutdown_end_time, false);
      let date = new Date(parseInt(row.start_time) * 1000);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      return Y + M + D;
      // return row.end_time
    },

    // 反显机检时间
    formatTime(row) {
      if (row.macp_status === 3) return '暂无';
      let date = new Date(parseInt(row.start_time) * 1000);
      let date1 = new Date(parseInt(row.end_time) * 1000);
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      let ho = (date1.getHours() < 10 ? '0' + date1.getHours() : date1.getHours()) + ':';
      let mo = date1.getMinutes() < 10 ? '0' + date1.getMinutes() : date1.getMinutes();
      return h + m + '~' + ho + mo;
    }
  }
};
</script>

<style scoped lang="scss">
.iconfont {
  font-size: 40px;
}
.vd_div_c {
  width: 80px;
  height: 80px;
  position: relative;
}
.vd_div_new {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vd_button_group {
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_disable1 {
  color: #ffaa00;
}
.vd_disable2 {
  color: #33ff00;
}
.vd_wei {
  color: #ff8511;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}

.vd_flex_right {
  display: flex;
  justify-content: flex-end;
}
.vd_bor {
  border-bottom: 1px dashed #000000;
}
.vd_three {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
