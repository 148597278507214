<template>
  <div>
    <el-popover placement="right" width="600" @hide="hideChange" trigger="click">
      <div class="left" id="imgbox" ref="previewImgDiv" @mousewheel="handleMousewheel" @mousedown="handleMouseDown">
        <el-image
          :style="{ transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)` }"
          :src="macpFlag"
          style="width: 600px; height: 600px"
          ref="imgDiv"
        />
      </div>
      <el-link v-if="downloadFlag === 1" type="primary" @click="download">下载图片</el-link>
      <div slot="reference" class="vd_div_c" v-loading="loadFlag" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-image lazy :src="macpFlag" @load="loadChange" @error="errorChange" />
        <div class="vd_div">
          <span v-if="macpFlagO === 1" class="iconfont icon-duihao" />
          <span v-else-if="macpFlagO === 0" class="iconfont icon-cuohaoyuan" />
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadFlag: true,
      scale: 1,
      translateX: 0,
      translateY: 0
    };
  },
  props: {
    macpFlag: { type: String, required: true },
    macpFlagO: { type: Number, required: true },
    downloadFlag: { type: Number, default: 0 }
  },
  methods: {
    //下载图片
    download() {
      if (this.macpFlag) {
        let docuUrl = this.helper.megPath(this.macpFlag, 0);
        // http://localhost:8885/file/document/20211216/1639639858419.jpg
        let name = '二维码.jpg';
        this.helper.downloadItem(docuUrl, name, 0);
      } else {
        this.$message.warning('没有图片可供下载');
      }
    },
    loadChange(e) {
      this.loadFlag = false;
    },
    errorChange(e) {},
    handleMousewheel(event) {
      this.scale = this.scale + event.wheelDelta / 1200;
      return false;
    },
    handleMouseDown(event) {
      event.preventDefault();
      if (event.target.tagName !== 'IMG') {
        return false;
      }
      let div = this.$refs.previewImgDiv;
      let originX = event.screenX;
      let originY = event.screenY;
      let translateX = this.translateX;
      let translateY = this.translateY;
      const move = e => {
        let afterX = e.screenX;
        let afterY = e.screenY;
        this.translateX = translateX + (afterX - originX) / this.scale;
        this.translateY = translateY + (afterY - originY) / this.scale;
      };
      div.addEventListener('mousemove', move);
      div.addEventListener('mouseup', () => {
        div.removeEventListener('mousemove', move);
      });
    },
    hideChange() {
      this.scale = 1;
      (this.translateX = 0), (this.translateY = 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.iconfont {
  font-size: 40px;
}
.vd_div_c {
  width: 80px;
  height: 80px;
  position: relative;
}
.vd_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_popimg {
  width: 500px;
  height: 500px;
}
::v-deep .circular {
  width: 30px;
}
::v-deep .el-loading-mask {
  background-color: rgb(243 240 240 / 80%) !important;
}
.left {
  position: relative;
  width: 600px;
  height: 600px;
  background-color: #fff;
  overflow: hidden;
}
.img {
  position: absolute;
  top: 5px;
  left: 7px;
  max-width: 923px;
  max-height: 460px;
  cursor: move;
}
</style>
